/**
 * main.scss
 *
 * Import Bootstrap 5 custom build
 * Comment out unused styles
 *
 */

// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';

// 2. Include any default variable overrides here
// import theme specific variables
@import './theme-variables'; // this is probably unnecessary, variables Bootstrap 4

// 3. Include remainder of required Bootstrap stylesheets
// Configuration
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 4. Include any optional Bootstrap components as you like

// Layout & components
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
//@import "~bootstrap/scss/accordion";
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
//@import "~bootstrap/scss/toasts";
//@import "~bootstrap/scss/modal";
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
// @import "~bootstrap/scss/carousel";
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/offcanvas';
//@import "~bootstrap/scss/placeholders";

// Helpers
@import '~bootstrap/scss/helpers';

// Utilities
@import '~bootstrap/scss/utilities/api';
// scss-docs-end import-stack

:root {
  --bs-select-padding-y: #{$form-select-padding-y}; //
  --bs-select-padding-x: #{$form-select-padding-x}; //
  --bs-select-font-size: #{$form-select-font-size}; //
  --bs-select-indicator-padding: #{$form-select-indicator-padding};
  --bs-select-font-weight: #{$form-select-font-weight}; //
  --bs-select-line-height: #{$form-select-line-height}; //
  --bs-select-color: #{$form-select-color}; //
  --bs-select-bg: #{$form-select-bg}; //
  --bs-select-disabled-color: #{$form-select-disabled-color};
  --bs-select-disabled-bg: #{$form-select-disabled-bg};
  --bs-select-disabled-border-color: #{$form-select-disabled-border-color};
  --bs-select-bg-position: #{$form-select-bg-position};
  --bs-select-bg-size: #{$form-select-bg-size};
  --bs-select-indicator: #{escape-svg($form-select-indicator)};
  --bs-select-feedback-icon-padding-end: #{$form-select-feedback-icon-padding-end};
  --bs-select-feedback-icon-position: #{$form-select-feedback-icon-position};
  --bs-select-feedback-icon-size: #{$form-select-feedback-icon-size};
  --bs-select-border-width: #{$form-select-border-width}; //
  --bs-select-border-color: #{$form-select-border-color}; //
  --bs-select-focus-border-color: #{$form-select-focus-border-color}; //
  --bs-select-padding-y-sm: #{$form-select-padding-y-sm};
  --bs-select-padding-x-sm: #{$form-select-padding-x-sm};
  --bs-select-font-size-sm: #{$form-select-font-size-sm};
  --bs-select-padding-y-lg: #{$form-select-padding-y-lg};
  --bs-select-padding-x-lg: #{$form-select-padding-x-lg};
  --bs-select-font-size-lg: #{$form-select-font-size-lg};
  @if $enable-rounded {
    --bs-select-border-radius: #{$form-select-border-radius}; //
  } @else {
    --bs-select-border-radius: 0; //
  }
}
